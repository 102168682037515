import { FC, useState } from 'react';
import { Button, IconButton, ThemeProvider, Typography } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';

import { Routes } from ':constants/routes';

import styles from './styles.module.scss';
import { Slider, Slide } from './Slider';
import { SlideContent } from './SlideContent';
import { useVariant } from './hooks';

import { sliderItems } from ':mocks';
import { Modal } from ':components';

const videoParams = '?skinColor=fdd835';
// const autoplay = '&autoplay=true';

/**
 * @description Компонент отображения слайдов
 */
export const WhyBeeline: FC = () => {
  const navigate = useNavigate();
  const { buttonSize, buttonVariant, subtitleVariant, titleVariant, isNormalViewUp } = useVariant();
  const [openVideo, setOpenVideo] = useState('');

  const handleRedirectToVacancies = () => navigate(Routes.Vacancies);

  const handleOvenVideo = (url: string) => () => {
    setOpenVideo(url);
  };

  const handleCloseVideo = () => setOpenVideo('');

  const slides = sliderItems.map<Slide>(({ caption, image, name, url }) => ({
    render: ({ isSelected, disableAnimations }) => {
      return (
        <SlideContent
          caption={caption}
          disableAnimations={disableAnimations}
          embedded
          name={name}
          onClick={handleOvenVideo}
          selected={isSelected}
          src={image}
          url={url}
        />
      );
    },
  }));

  return (
    <div className={styles.whyBeeline}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Typography variant={titleVariant}>Почему я выбрал билайн?</Typography>
          <Typography variant={subtitleVariant}>
            Посмотрите интервью с&nbsp;нашими сотрудниками и&nbsp;узнай, что они ценят в&nbsp;билайне
          </Typography>
        </div>

        {isNormalViewUp ? (
          <div className={styles.slides}>
            {sliderItems.map(({ caption, image, name, url }) => {
              return (
                <SlideContent
                  key={name}
                  caption={caption}
                  name={name}
                  onClick={handleOvenVideo}
                  src={image}
                  url={url}
                />
              );
            })}
          </div>
        ) : (
          <Slider slides={slides} />
        )}

        <Button className={styles.button} onClick={handleRedirectToVacancies} size={buttonSize} variant={buttonVariant}>
          Присоединиться к команде
        </Button>
      </div>
      <Modal className={styles.modal} onOutsideClick={handleCloseVideo} open={!!openVideo.length}>
        <div className={styles.modalContent}>
          <ThemeProvider className={styles.closeIcon} theme="dark">
            <IconButton
              className={styles.closeIconButton}
              iconName={Icons.Close}
              onClick={handleCloseVideo}
              size="large"
            />
          </ThemeProvider>
          <iframe
            allow="clipboard-write; autoplay"
            allowFullScreen
            className={styles.frame}
            // mozallowfullscreen="true"
            src={openVideo + videoParams}
            title="1"
            // webkitAllowFullScreen="true"
          />
        </div>
      </Modal>
    </div>
  );
};
