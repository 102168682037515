import { Button, Typography } from '@beeline/design-system-react';
import { FC } from 'react';

import styles from './styles.module.scss';

import EmptyList from ':assets/svg/empty_list.svg';

interface Props {
  /** Очистить фильтры */
  onClear: () => void;
}

/**
 * @description Компонент отображения, когда вакнсии были не найдены
 */
export const NoVacanciesFound: FC<Props> = ({ onClear: handleClear }) => {
  return (
    <div className={styles.root}>
      <img alt="Мы не нашли вакансию по такому запросу" src={EmptyList} />

      <div className={styles.content}>
        <Typography className={styles.title} variant="h4">
          Мы не нашли вакансию по такому запросу
        </Typography>
        <Typography variant="body1">
          Попробуйте поменять запрос в поиске. Или воспользуйтесь фильтрами: выберите город, интересное вам направление
        </Typography>
      </div>

      <Button onClick={handleClear} variant="contained">
        Очистить запрос
      </Button>
    </div>
  );
};
