import IMask, { MaskedDynamic, MaskedPattern } from 'imask';

import { JobTypes } from ':constants';

/**
 * @param value Значение
 * @returns Значение не null и undefined
 */
export const isDefined = <T>(value: T | null | undefined): value is T => {
  return value !== undefined && value !== null;
};

/**
 * @description Удаление null и undefined значений в объекте
 */
export const stripUndefined = (obj: Record<string, any>) => {
  const filteredEntries = Object.entries(obj).filter((entry) => isDefined(entry[1]));
  return Object.fromEntries(filteredEntries);
};

/**
 * @description Добавление параметров к url
 */
export const concatParams = (url: string, paramsQuery: string) => {
  const divider = url.includes('?') ? '&' : '?';
  return `${url}${divider}${paramsQuery}`;
};

/**
 * @description Проверка на объект
 * @see https://github.com/reduxjs/redux/blob/master/src/utils/isPlainObject.ts
 */
export function isPlainObject(obj: any): obj is object {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  let proto = obj;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }

  return Object.getPrototypeOf(obj) === proto || Object.getPrototypeOf(obj) === null;
}

/**
 * @description Проверка возможности конвертации в json
 */
export const isJsonifiable = (body: any) => {
  return typeof body === 'object' && (isPlainObject(body) || Array.isArray(body));
};

/**
 * @description Проверка типа вакансии
 */
export const isProfVacancy = (jobTypeId: string) => {
  return JobTypes.Prof.includes(jobTypeId);
};

/**
 * @description Проверка типа вакансии
 */
export const isInternVacancy = (jobTypeId: string) => {
  return JobTypes.Intern.includes(jobTypeId);
};

/**
 * @description Проверка типа вакансии
 */
export const isMassVacancy = (jobTypeId: string) => {
  return JobTypes.Mass.includes(jobTypeId);
};

/**
 * @description Маска Телеграмма
 */
export const telegramMaskedInstance = IMask.createMask({
  dispatch: (appended: string, dynamicMasked: MaskedDynamic) => {
    const { compiledMasks, value } = dynamicMasked;

    const [phoneMask, nicknameMask, emptyMask] = compiledMasks;

    const newValue = value + appended;

    if (/[0-9+]/.test(newValue[0])) {
      return phoneMask;
    }

    if (/[A-Za-z@]/.test(newValue[0])) {
      return nicknameMask;
    }

    return emptyMask;
  },
  mask: [
    {
      mask: '+7 (000) 000-00-00',
    },
    {
      definitions: {
        '#': /[A-Za-z]/,
        $: /[A-Za-z\d_]/,
      },
      mask: `{@}#${'$'.repeat(31)}`,
    },
    {
      mask: '',
    },
  ],
}) as unknown as MaskedPattern;
