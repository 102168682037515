import { ChangeEvent, FC, useRef } from 'react';
import {
  Button,
  Icon,
  MaskField,
  TextArea,
  TextField,
  Typography,
  FileUploaderListItem,
} from '@beeline/design-system-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { zodResolver } from '@hookform/resolvers/zod';

import { useMobileId } from ':hooks/useMobileId';
import { useProfResume } from ':hooks/useProfResume';
import { telegramMaskedInstance } from ':utils/common';

import * as styles from './styles.module.scss';
import { schema } from './schema';

import { VacancyFormFooter, Header, VacancyFormHeader, Modal, MobileId } from ':components';
import { BVacancy } from ':types';

type Props = {
  /** Функция закрытия модального окна */
  onClose: (open: boolean) => void;
  /** Ошибка отправки */
  onError: () => void;
  /** Функция успешного действия */
  onSuccess: () => void;
  /** Данные по вакансии */
  vacancy: BVacancy;
};

/** Типы полей формы */
type ProfVacancyFormType = z.infer<typeof schema>;

/**
 * @description Компонент отлика на вакансию проф подбора
 */
export const ProfVacancyForm: FC<Props> = ({ onClose, onError, onSuccess, vacancy }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {
    isOpen,
    close: handleClose,
    onChangeNumber: handleChangeNumber,
    onRetry: handleRetry,
    onSuccess: handleSuccessId,
  } = useMobileId({ onSuccess });
  const handleClick = () => {
    onClose(false);
  };
  const { mutateAsync: profResumeApply } = useProfResume({ onError });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { isSubmitSuccessful, errors, isValid },
  } = useForm<ProfVacancyFormType>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const uploadedFile = watch('resume_file');

  const onSubmit = async (data: ProfVacancyFormType) => {
    try {
      profResumeApply({
        ...data,
        external_system_vacancy_id: vacancy?.external_system_vacancy_id,
        external_system_name: vacancy?.external_system_name,
      });
    } catch (e) {
      console.error(e);
    } finally {
      handleSuccessId();
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setValue('resume_file', file);
    }
  };

  const handleRemoveFilte = () => {
    setValue('resume_file', undefined);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header className={styles.header} />

      <VacancyFormHeader onClick={handleClick} title={vacancy.name} />

      <div className={styles.profVacancyForm}>
        <div className={styles.formWrapper}>
          <div>
            <Typography variant="subtitle1">Контактные данные</Typography>

            <div className={styles.contacts}>
              <TextField
                fullWidth
                label="Фамилия*"
                {...register('last_name', { required: true })}
                error={!!errors.last_name?.message}
                helperText={errors.last_name?.message}
              />
              <TextField
                fullWidth
                label="Имя*"
                {...register('first_name', { required: true })}
                error={!!errors.first_name?.message}
                helperText={errors.first_name?.message}
              />
              <TextField fullWidth label="Отчество" {...register('middle_name', {})} />
              <MaskField
                fullWidth
                label="Ссылка на телеграм"
                {...register('telegram_url', {})}
                maskConfig={telegramMaskedInstance}
              />
              <MaskField
                error={!!errors.phone?.message}
                fullWidth
                helperText={errors.phone?.message || 'Мы отправим смс для подтверждения отклика'}
                label="Номер телефона*"
                maskConfig={{
                  mask: '+{7}(000)000-00-00',
                }}
                required
                {...register('phone', { required: true })}
              />
              <TextField fullWidth label="Почта" {...register('email', {})} />
            </div>
          </div>

          <div className={styles.upload}>
            <Typography className={styles.upload_title} variant="subtitle1">
              Прикрепите резюме или добавьте ссылку*
            </Typography>

            <div className={styles.selectResume}>
              <Button
                disabled={!!uploadedFile}
                onClick={handleButtonClick}
                startIcon={<Icon iconName={Icons.Attachment} />}
              >
                Загрузить резюме
              </Button>
              <Typography className={styles.title} variant="subtitle3">
                Один файл или документ pdf, doc, docx, rtf до 10 Мб
              </Typography>
            </div>

            {uploadedFile && (
              <FileUploaderListItem
                className={styles.fileListUploader}
                iconName={Icons.AddRowDown}
                id={1}
                name={uploadedFile.name}
                onRemove={handleRemoveFilte}
                type="pdf"
              />
            )}

            {/* Скрытый input */}
            <input
              {...register('resume_file', {})}
              ref={fileInputRef}
              accept=".pdf, .docx, .rtf"
              id="fileInput"
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
              type="file"
            />

            <TextField
              fullWidth
              helperText={errors.resume_url?.message}
              label="Ссылка на резюме*"
              {...register('resume_url')}
              error={!!errors.resume_url?.message}
            />
            <TextArea
              {...register('cover_letter')}
              className={styles.textarea}
              fullWidth
              helperText="Расскажите о своем опыте и достижениях.
              Добавьте сопроводительное письмо или ссылку на портфолио"
              label="Дополнительная информация"
            />
          </div>

          <Typography variant="caption">* Поля обязательные для заполнения</Typography>
        </div>
      </div>

      <Modal className={styles.mobileIdModal} open={isOpen}>
        <MobileId
          number={getValues().phone}
          onChangeNumber={handleChangeNumber}
          onClose={handleClose}
          onRetry={handleRetry}
          onSuccess={handleSuccessId}
        />
      </Modal>

      <VacancyFormFooter isDisabled={!isValid} isSubmitting={isSubmitSuccessful} />
    </form>
  );
};
