/**
 * @description Вакансия
 */
export interface BVacancy {
  /** Город, где осуществляется найм по вакансии */
  city: string[];
  /** Описание вакансии в формате rich-text */
  description: string;
  /** Специализация вакансии */
  direction: string;
  /** Название системы */
  external_system_name: string;
  /** Номер вакансии в системе */
  external_system_vacancy_id: string;
  /** Формат работы */
  format?: string;
  /** Требуемый опыт работы */
  grade: string;
  /** Минимальная ожидаемая заработная плата */
  gross_salary: string;
  /** Зарплата От */
  gross_salary_from?: string;
  /** Идентификатор вакансии */
  id: string;
  /** Статус вакансии (архивна/активна) */
  is_active: boolean;
  /** Идентификатор воронки подбора */
  job_type_id: string;
  /** Название вакансии */
  name: string;
  /** Формат работы */
  work_format: string;
}

/**
 * @description Вакансия в списке вакансий
 * TODO: в спецификации gross_salary вместо gross_salary_from
 * @see https://bwiki.beeline.ru/pages/viewpage.action?pageId=389572051
 */
export interface BVacancyShort {
  /** Город размещения вакансии */
  city: string[];
  /** Требуемый опыт работы */
  grade: string;
  /** Зарплата */
  gross_salary_from: string;
  /** Уникальный идентификатор вакансии */
  id: string;
  /** Тип вакансии */
  job_type_id: string;
  /** Название вакансии */
  name: string;
  /** Название роли */
  role: string;
  /** Формат работы */
  work_format: string;
}

/**
 * @description Пагинация для вакансий
 */
export interface BVacancyPage {
  /** Количество вакансий для отображения */
  count: number;
  /** Ссылка на следующий набор вакансий */
  next: string | null;
  /** Ссылка на предыдущий набор вакансий */
  previous: string | null;
  /** Список вакансий */
  results: BVacancyShort[];
}

export interface BPresearch {
  /** Число отображаемых вакансий */
  count: number;
  /** Название вакансии для подсказки */
  vacancy_name: string;
  /** Число вакансий с указанным названием */
  vacancy_number: number;
}

/**
 * @description Город
 */
export interface BCity {
  /** Регион  */
  display_region: string;
  /** Идентификатор города */
  id: string;
  /** Название города */
  name: string;
  /** Число вакансий, размещённых в данном городе */
  vacancies_number: number;
}

/**
 * @description Опыт работы, требуемый для вакансии
 */
export interface BWorkGrade {
  /** Идентификатор */
  id: string;
  /** Название */
  name: string;
}

/**
 * @description Формат работы
 */
export interface BWorkFormat {
  /** Идентификатор */
  id: string;
  /** Название */
  name: string;
}

export interface BWorkFormats {
  /** Формат работы */
  work_format: BWorkFormat[];
}

/**
 * @description Роль
 */
export interface BRole {
  /** Количество ролей */
  role_count: number;
  /** Идентификатор */
  role_id: string;
  /** Название роли */
  role_name: string;
}

/**
 * @description Направления
 */
export type BDirection = {
  /** Количество направлений */
  direction_count: number;
  /** Идентификатор направления */
  direction_id: string;
  /** Название направления */
  direction_name: string;
  /** Список ролей */
  roles: BRole[];
};

/**
 * @description Поля для отправки отклика по форме Проф.подбора
 */
export type BProfResume = {
  /** Дополнительная информация */
  cover_letter?: string;
  /** Почта */
  email?: string;
  /** Название системы куда идет отклик */
  external_system_name: string;
  /** id вакансии */
  external_system_vacancy_id?: string;
  /** Имя */
  first_name: string;
  /** Фамилия */
  last_name: string;
  /** Отчество */
  middle_name?: string;
  /** Номер телефона */
  phone: string;
  /** Файл резюме */
  resume_file?: File;
  /** Ссылка на резюме */
  resume_url?: string;
  /** Ссылка на телеграм */
  telegram_url?: string;
};

/**
 * @description Поля для отправки отклика по форме Масс.подбора
 */
export type BMassResume = {
  /** id вакансии */
  external_system_vacancy_id?: string;
  /** Фамилия и имя */
  full_name: string;
  /** Номер телефона */
  phone: string;
};

/**
 * @description Справочник ролей
 */
export enum BRoles {
  /** Менеджер по рекламе */
  ADVERTISEMENT_MANAGER = '069',
  /** Аналитик */
  ANALYST = '046',
  /** Аналитическая отчетность */
  ANALYTICS_REPORTING = '037',
  /** Аналитическая отчетность 2 */
  ANALYTICS_REPORTING_2 = '075',
  /** Архитектор */
  ARCHITECT = '044',
  /** Арт-директор */
  ART_DIRECTOR = '070',
  /** Поддержка B2B клиентов */
  B2B_CUSTOMER_SUPPORT = '146',
  /** Бэкэнд-разработчик */
  BACKEND_DEVELOPER = '048',
  /** BI аналитик */
  BI_ANALYST = '149',
  /** BI-разработчик */
  BI_DEVELOPER = '150',
  /** Директор филиала */
  BRANCH_OFFICE_DIRECTOR = '018',
  /** Бизнес-аналитик */
  BUSINESS_ANALYST = '148',
  /** Менеджер по контенту / редактор */
  CONTENT_MANAGER_EDITOR = '073',
  /** Копирайтер */
  COPYWRITER = '072',
  /** Поддержка клиентов */
  CUSTOMER_SUPPORT = '095',
  /** Поддержка клиентов 2 */
  CUSTOMER_SUPPORT_2 = '138',
  /** Поддержка клиентов 3 */
  CUSTOMER_SUPPORT_3 = '140',
  /** Аналитик данных */
  DATA_ANALYST = '001',
  /** Инженер данных */
  DATA_ENGINEER = '002',
  /** Data Scientist */
  DATA_SCIENTIST = '003',
  /** Дизайнер */
  DESIGNER = '071',
  /** DevOps инженер */
  DEVOPS_ENGINEER = '052',
  /** Агент прямых продаж */
  DIRECT_SALES_AGENT = '114',
  /** Энергетический инженер */
  ENERGY_ENGINEER = '041',
  /** Менеджер по мероприятиям */
  EVENT_MANAGER = '068',
  /** Фронтэнд-разработчик */
  FRONTEND_DEVELOPER = '049',
  /** Директор по персоналу */
  HR_DIRECTOR = '019',
  /** Установщик */
  INSTALLER = '040',
  /** IT-инфраструктура */
  IT_INFRASTRUCTURE = '053',
  /** Администрирование IT-решений */
  IT_SOLUTION_ADMINISTRATION = '054',
  /** Управление 1 */
  MANAGEMENT_1 = '151',
  /** Управление 2 */
  MANAGEMENT_2 = '147',
  /** Управление 3 */
  MANAGEMENT_3 = '039',
  /** Управление 4 */
  MANAGEMENT_4 = '078',
  /** Управление 5 */
  MANAGEMENT_5 = '099',
  /** Управление 6 */
  MANAGEMENT_6 = '145',
  /** Маркетолог */
  MARKETER = '066',
  /** Мерчандайзер */
  MERCHANDISER = '106',
  /** Методист */
  METHODOLOGIST = '107',
  /** Разработчик мобильных приложений */
  MOBILE_APP_DEVELOPER = '056',
  /** Сетевой администратор */
  NETWORK_ADMINISTRATOR = '134',
  /** Инженер сетевого строительства */
  NETWORK_CONSTRUCTION_ENGINEER = '033',
  /** Сетевой инженер */
  NETWORK_ENGINEER = '136',
  /** Сетевой инженер операций */
  NETWORK_OPS_ENGINEER = '032',
  /** Инженер по планированию и оптимизации сетей */
  NETWORK_PLANNING_OPTIMIZATION_ENGINEER = '034',
  /** Офисный специалист */
  OFFICE_SPECIALIST = '113',
  /** Операционный директор */
  OPERATING_DIRECTOR = '020',
  /** Другое 1 */
  OTHER_1 = '006',
  /** Другое 2 */
  OTHER_2 = '055',
  /** Другое 3 */
  OTHER_3 = '042',
  /** Другое 4 */
  OTHER_4 = '021',
  /** Другое 5 */
  OTHER_5 = '079',
  /** Другое 6 */
  OTHER_6 = '101',
  /** Другое 7 */
  OTHER_7 = '111',
  /** Развитие партнерских продаж */
  PARTNERSHIP_SALES_DEVELOPMENT = '100',
  /** Специалист по аренде позиций */
  POSITION_RENTAL_SPECIALIST = '035',
  /** Постер */
  POSTER = '115',
  /** Владелец продукта / услуги */
  PRODUCT_SERVICE_OWNER = '057',
  /** Управление проектами 1 */
  PROJECT_MANAGEMENT_1 = '050',
  /** Управление проектами 3 */
  PROJECT_MANAGEMENT_3 = '036',
  /** Управление проектами 4 */
  PROJECT_MANAGEMENT_4 = '077',
  /** Управление проектами 5 */
  PROJECT_MANAGEMENT_5 = '098',
  /** Управление проектами 6 */
  PROJECT_MANAGEMENT_6 = '109',
  /** Управление проектами 7 */
  PROJECT_MANAGEMENT_7 = '143',
  /** PR-менеджер */
  PR_MANAGER = '067',
  /** Исследования */
  RESEARCH = '076',
  /** Управление розничными продажами */
  RETAIL_SALES_MANAGEMENT = '103',
  /** Аналитика продаж */
  SALES_ANALYTICS = '097',
  /** Аналитика продаж 2 */
  SALES_ANALYTICS_2 = '108',
  /** Развитие каналов продаж */
  SALES_CHANNEL_DEVELOPMENT = '104',
  /** Директор по продажам */
  SALES_DIRECTOR = '017',
  /** Представитель по продажам */
  SALES_REPRESENTATIVE = '105',
  /** Поддержка продаж */
  SALES_SUPPORT = '096',
  /** Поддержка продаж 2 */
  SALES_SUPPORT_2 = '110',
  /** Продажи корпоративным клиентам */
  SALES_TO_CORPORATE_CLIENTS = '091',
  /** Продажи малому и среднему бизнесу */
  SALES_TO_SMB = '092',
  /** Инженер по обслуживанию */
  SERVICE_ENGINEER = '137',
  /** Менеджер SMM */
  SMM_MANAGER = '074',
  /** Директор магазина */
  STORE_DIRECTOR = '112',
  /** Системный аналитик */
  SYSTEM_ANALYST = '045',
  /** Инженер систем */
  SYSTEM_ENGINEER = '135',
  /** Технический директор */
  TECHNICAL_DIRECTOR = '152',
  /** Техническая поддержка */
  TECHNICAL_SUPPORT = '142',
  /** Телефонные продажи */
  TELEPHONE_SALES = '141',
  /** Телемаркетинг */
  TELESALES = '094',
  /** Тестировщик */
  TESTER = '051',
  /** Дизайнер UI/UX */
  UI_UX_DESIGNER = '047',
  /** Работа с государственными структурами */
  WORK_WITH_GOVERNMENT_STRUCTURES = '093',
  /** Работа с телекоммуникациями 1 */
  WORK_WITH_TELCOS_1 = '038',
  /** Работа с телекоммуникациями 2 */
  WORK_WITH_TELCOS_2 = '102',
}
