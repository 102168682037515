import { BWorkFormat } from ':types';

export const WorkFormatMock: BWorkFormat[] = [
  {
    id: '1',
    name: 'Офис',
  },
  {
    id: '2',
    name: 'Удаленка',
  },
  {
    id: '3',
    name: 'Гибрид',
  },
];
