import { KeyboardEvent, useState } from 'react';
import { Button, ThemeProvider, Typography, Option, Autocomplete, Skeleton } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

import men from ':assets/img/main_men.png';
import women from ':assets/img/main_women.png';
import { BPresearch } from ':types';
import { useDebounce, usePresearch } from ':hooks';

const linkTags = [
  'Мерчендайзер',
  'Backend',
  'Frontend',
  'Инженер связи',
  'Продавец-консультант',
  'Оператор колл-центра',
  'Без опыта',
];

const loadingSkeletonRows = [...Array(4).keys()];

const onLoadingContent = (
  <div className={styles.skeletonDropdown}>
    {loadingSkeletonRows.map((value) => (
      <div key={value}>
        <Skeleton height={22} radius={12} />
        <Skeleton className={styles.circleSkeleton} height={22} variant="circle" width={22} />
      </div>
    ))}
  </div>
);

const noOptionContent = (
  <div className={styles.noResult}>
    <Typography variant="body2">Нет результатов</Typography>
  </div>
);

/**
 * @description Блок с поиском вакансий
 */
export const SearchBlock = () => {
  const [searchValue, setSearchValue] = useState('');
  const { buttonVariant, subtitleVariant, findButton, tagVariant, titleVariant } = useVariant();
  const navigate = useNavigate();
  const debouncedSearch = useDebounce(searchValue);
  const { data: presearchData, isLoading } = usePresearch(debouncedSearch);

  const redirectWithSearch = (value: string) => {
    localStorage.removeItem('filters');
    localStorage.setItem('searchName', `?name=${value}`);
    navigate(`/vacancies`);
  };

  const handleTagClick = (tag: string) => () => {
    redirectWithSearch(tag);
  };

  const handleSearchButtonClick = () => {
    redirectWithSearch(searchValue);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  const handleChange = (option: Option<BPresearch>) => {
    redirectWithSearch(option.value.vacancy_name);
  };

  const handleInputChange = (value: string) => {
    setSearchValue(value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  const renderOptions = (option: Option<BPresearch>) => {
    const { vacancy_name, vacancy_number } = option.value;
    return (
      <div className={styles.searchDropdownValue}>
        <Typography className={styles.searchName} variant="body2">
          {vacancy_name}
        </Typography>
        <Typography variant="caption">{vacancy_number}</Typography>
      </div>
    );
  };

  const onRenderValue = (option: Option<BPresearch>) => {
    return option.value.vacancy_name;
  };

  const options: Option<BPresearch>[] = presearchData?.map((d) => ({ id: d.vacancy_name, value: d })) || [];
  const hideDropDown = debouncedSearch.length < 3;

  return (
    <ThemeProvider className={styles.searchBlock} theme="dark">
      <div className={styles.mainBlock}>
        <Typography variant={titleVariant}>Открой билайн для&nbsp;себя</Typography>
        <Typography className={styles.subtitle} variant={subtitleVariant}>
          Мы стремимся оказывать сервис самого высокого уровня, создавая цифровые и телеком-продукты не&nbsp;только для
          наших клиентов, но&nbsp;и&nbsp;сотрудников
        </Typography>
        <div className={styles.searchRow}>
          <Autocomplete
            fullWidth
            hideDropDown={hideDropDown}
            inputClassName={styles.search}
            loading={isLoading}
            loadingContent={onLoadingContent}
            makeOption={renderOptions}
            noOptionsContents={noOptionContent}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onInputClear={handleClear}
            onKeyDown={handleKeyDown}
            onSubmit={handleSearchButtonClick}
            options={options}
            placeholder="Введите название вакансии"
            renderValue={onRenderValue}
            size="medium"
            type="search"
            value={null}
          />
          {findButton && (
            <Button onClick={handleSearchButtonClick} size={buttonVariant} variant="contained">
              Найти вакансию
            </Button>
          )}
        </div>
        <div className={styles.tagRow}>
          {linkTags.map((tag) => (
            <Typography key={tag} className={styles.linkTag} onClick={handleTagClick(tag)} variant={tagVariant}>
              #{tag}
            </Typography>
          ))}
        </div>
      </div>
      <ThemeProvider className={styles.photos} theme="light">
        <div className={styles.background}>
          <img alt="men" src={men} />
        </div>
        <div className={styles.background}>
          <img alt="women" src={women} />
        </div>
      </ThemeProvider>
    </ThemeProvider>
  );
};
