import { z } from 'zod';

export const schema = z.object({
  full_name: z.string().min(1, { message: 'Поле является обязательным' }),
  phone: z
    .string()
    .regex(/\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/, {
      message: 'Неверный формат телефонного номера',
    })
    .min(8),
});
