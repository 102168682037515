import { BDirection } from ':types';

/** Интерфейс для структуры ролей */
export interface TreeNodeProp {
  /** Выбранный */
  checked?: boolean;
  /** Дочерние ноды */
  childs?: TreeNodeProp[];
  /** Количество вакансий */
  count: number;
  /** Отфильтрованый */
  hidden?: boolean;
  /** ID */
  id: string;
  /** Название */
  name: string;
}

/** Структурирует массив ролей и фильтрует их */
export const filteredDirections = (directions: BDirection[], searchValue: string, values?: string[]) =>
  directions.map(({ direction_id, direction_name, direction_count, roles }) => {
    const { childs, hidden } = roles.reduce<{ childs: TreeNodeProp[]; hidden: boolean }>(
      (acc, { role_id, role_name, role_count }) => {
        const hiddenRole = !role_name.toLowerCase().includes(searchValue);
        acc.childs.push({
          id: role_id,
          name: role_name,
          count: role_count,
          checked: values?.includes(role_id),
          hidden: hiddenRole,
        });
        acc.hidden = acc.hidden && hiddenRole;
        return acc;
      },
      { childs: [], hidden: true },
    );
    return {
      id: direction_id,
      name: direction_name,
      count: direction_count,
      childs,
      hidden: hidden && !direction_name.toLowerCase().includes(searchValue),
    };
  });
