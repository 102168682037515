import { Button, Icon, Progress, Search, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { generatePath } from 'react-router-dom';
import { ChangeEvent, useCallback, useState } from 'react';

import { NoVacanciesFound } from ':components/NoVacanciesFound';
import { useVacancies, VacanciesFilter } from ':hooks/useVacancies';
import { useDebounce } from ':hooks/useDebounce';

import styles from './styles.module.scss';
import {
  useChangeSearchParams,
  usePageObserve,
  usePersistSearchParams,
  useVacanciesData,
  useVacanciesSelect,
} from './hooks';
import { FilterCard } from './components/FilterCard';

import { Routes } from ':constants';
import { Modal, VacancyCard } from ':components';
import { VacanciesSkeleton } from ':components/Skeletons/VacanciesSkeleton';

/**
 * @description Страница Вакансий
 */
export const Vacancies = () => {
  const [searchValue, setSearchValue] = useState('');
  const [modalFilterOpen, setModalFilterOpen] = useState(false);
  const [filter, setFilter] = useState<VacanciesFilter>({ enabled: false });
  const debouncedFilter = useDebounce<VacanciesFilter>(filter);
  const { data: vacanciesData, fetchNextPage, isFetchingNextPage, isFetching } = useVacancies(debouncedFilter);

  const { cities, directions, workFormats, workGrade, isLoading } = useVacanciesData();
  const { isDisability, isInternship, selectedCities, selectedGrades, selectedRoles, selectedWorkFormat } =
    useVacanciesSelect(cities, workFormats, directions, workGrade);

  const addFilter = useCallback((newFilters: VacanciesFilter) => {
    setFilter((prev) => ({ ...prev, ...newFilters }));
  }, []);

  usePersistSearchParams(addFilter, setSearchValue);
  const { handleCheckboxChange, handleCheckboxTreeChange, handleSelectChange, clearFilters } =
    useChangeSearchParams(addFilter);

  const { isLargeViewUp } = usePageObserve(isFetchingNextPage, fetchNextPage, setModalFilterOpen);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value.length > 2) {
      addFilter({ name: value });
    }
    if (!value.length) {
      addFilter({ name: undefined });
    }
  };

  const handleSearchClear = () => {
    setSearchValue('');
    addFilter({ name: undefined });
  };

  const handleResetFilters = () => {
    setFilter({ enabled: true });
    clearFilters();
  };

  const handleClearQuery = () => {
    handleResetFilters();
    handleSearchClear();
  };

  const handleSetModalFilterOpen = () => setModalFilterOpen(true);
  const handleSetModalFilterClose = () => setModalFilterOpen(false);

  const vacancies = vacanciesData?.pages.flatMap((page) => page.results) || [];

  const disableReset =
    !selectedCities.length &&
    !selectedGrades.length &&
    !selectedWorkFormat.length &&
    !selectedRoles.length &&
    !isInternship &&
    !isDisability;

  if (isLoading || !debouncedFilter.enabled) {
    return <VacanciesSkeleton />;
  }

  return (
    <div className={styles.vacancies}>
      {/* Заголовок страницы */}
      <div className={styles.title}>
        <Typography className={styles.textTitle} variant="h1">
          Вакансии
        </Typography>

        {/* Поисковые элементы */}
        <div className={styles.searchControls}>
          <Search
            className={styles.mainbanner_search}
            fullWidth
            onChange={handleSearchChange}
            onClear={handleSearchClear}
            placeholder="Введите название вакансии или специализации"
            value={searchValue}
          />

          {!isLargeViewUp && (
            <Button className={styles.searchButton} onClick={handleSetModalFilterOpen} size="small">
              <Icon iconName={Icons.Filter} />
            </Button>
          )}
        </div>
      </div>

      <div className={styles.container}>
        {isLargeViewUp && (
          <FilterCard
            cities={cities}
            directions={directions}
            disableReset={disableReset}
            isDisability={isDisability}
            isInternship={isInternship}
            onCheckboxChange={handleCheckboxChange}
            onCheckboxTreeChange={handleCheckboxTreeChange}
            onResetFilters={handleResetFilters}
            onSelectChange={handleSelectChange}
            selectedCities={selectedCities}
            selectedGrades={selectedGrades}
            selectedRoles={selectedRoles}
            selectedWorkFormat={selectedWorkFormat}
            workFormats={workFormats}
            workGrade={workGrade}
          />
        )}

        {/* Отображение вакансий */}
        {vacancies.length === 0 && !isFetching ? (
          <NoVacanciesFound onClear={handleClearQuery} />
        ) : (
          <div className={styles.cards}>
            {vacancies.map((vacancy) => (
              <VacancyCard
                {...vacancy}
                key={vacancy.id}
                href={generatePath(Routes.Vacancy, { id: vacancy.id })}
                loading={isLoading}
              />
            ))}
            {(isFetching || isFetchingNextPage) && <Progress className={styles.progress} cycled shape="circle" />}
          </div>
        )}
      </div>
      <Modal className={styles.modal} open={!isLargeViewUp && modalFilterOpen}>
        <div className={styles.modalFilter}>
          <div className={styles.filterHeader}>
            <div className={styles.backTitle}>
              <Icon
                className={styles.icon}
                iconName={Icons.ArrowLeft}
                onClick={handleSetModalFilterClose}
                size="large"
              />
              <Typography variant="h5">Фильтр</Typography>
            </div>
            <Button disabled={disableReset} onClick={handleResetFilters} variant="plain">
              Сбросить
            </Button>
          </div>
          <FilterCard
            cities={cities}
            className={styles.filterCard}
            directions={directions}
            disableReset={disableReset}
            isDisability={isDisability}
            isInternship={isInternship}
            onCheckboxChange={handleCheckboxChange}
            onCheckboxTreeChange={handleCheckboxTreeChange}
            onResetFilters={handleResetFilters}
            onSelectChange={handleSelectChange}
            rootElementId="Modal_JB"
            selectedCities={selectedCities}
            selectedGrades={selectedGrades}
            selectedRoles={selectedRoles}
            selectedWorkFormat={selectedWorkFormat}
            workFormats={workFormats}
            workGrade={workGrade}
          />
        </div>
      </Modal>
    </div>
  );
};
