import { BCity } from ':types';

export const CitiesMock: BCity[] = [
  {
    id: '78',
    name: 'Самара',
    display_region: '',
    vacancies_number: 3,
  },
  {
    id: '1',
    name: 'Москва',
    display_region: '',
    vacancies_number: 2,
  },
  {
    id: '26',
    name: 'Воронеж',
    display_region: '',
    vacancies_number: 1,
  },
  {
    id: '1037',
    name: 'Светлый',
    display_region: 'Калининградская область',
    vacancies_number: 1,
  },
  {
    id: '1038',
    name: 'Тёмный',
    display_region: 'Калининградская область',
    vacancies_number: 1,
  },
  {
    id: '1039',
    name: 'Питер',
    display_region: 'Калининградская область',
    vacancies_number: 1,
  },
  {
    id: '1040',
    name: 'Новосибирск',
    display_region: 'Калининградская область',
    vacancies_number: 1,
  },
  {
    id: '1041',
    name: 'Казань',
    display_region: 'Калининградская область',
    vacancies_number: 1,
  },
  {
    id: '1042',
    name: 'Омск',
    display_region: 'Калининградская область',
    vacancies_number: 1,
  },
];
