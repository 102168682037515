import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants/endpoints';

import { API } from ':api';
import { BPresearch } from ':types';

/** Запрос поиска вакансий на главной странице */
export const usePresearch = (name: string) => {
  return useQuery<BPresearch[] | null>({
    queryKey: ['presearch', name],
    queryFn: () => {
      if (name.length > 2) {
        return API.get({
          url: Endpoints.presearch(),
          params: {
            name,
          },
        });
      }

      return null;
    },
  });
};
