import { FC, PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'clsx';

import styles from './styles.module.scss';

interface Props {
  /** стиль модального окна */
  className?: string;
  /** клик по пустой области */
  onOutsideClick?: () => void;
  /** показать модальное окно */
  open: boolean;
}

export const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  open,
  onOutsideClick: handleOutsideClick,
}) => {
  const rootElement = document.getElementById('root');

  useEffect(() => {
    // TODO Roman rezise => !open
    if (open) {
      document.body.classList.add(styles.hideScroll);
    }

    if (!open) {
      document.body.classList.remove(styles.hideScroll);
    }

    return () => {
      document.body.classList.remove(styles.hideScroll);
    };
  }, [open]);

  if (!open || !rootElement) {
    return null;
  }

  return createPortal(
    <div
      className={cn(styles.modal, className)}
      // Задали id для модалки с фильтрами, z-index модалки перекрывал dropdown <Select/>. ID нужно для applicationRootElementID
      id="Modal_JB"
      onClick={handleOutsideClick}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>,
    rootElement,
  );
};
