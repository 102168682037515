import { Icons } from '@beeline/design-tokens/js/iconfont/icons';

import { BRoles } from ':types/backend';

interface Vacancy {
  icon: Icons;
  roles?: BRoles[];
  subtitleList: string[][];
  title: string;
}

const adaptiveItCards: Vacancy[] = [
  {
    icon: Icons.Code,
    title: 'ИТ-решения и\xa0продукты',
    subtitleList: [['Разработка', 'Аналитика'], ['Управление продуктами'], ['Дизайн', 'BigData', 'QA']],
    roles: [
      BRoles.ANALYST,
      BRoles.ARCHITECT,
      BRoles.BACKEND_DEVELOPER,
      BRoles.BI_ANALYST,
      BRoles.BI_DEVELOPER,
      BRoles.BUSINESS_ANALYST,
      BRoles.DATA_ANALYST,
      BRoles.DATA_ENGINEER,
      BRoles.DATA_SCIENTIST,
      BRoles.DEVOPS_ENGINEER,
      BRoles.FRONTEND_DEVELOPER,
      BRoles.IT_INFRASTRUCTURE,
      BRoles.IT_SOLUTION_ADMINISTRATION,
      BRoles.MANAGEMENT_1,
      BRoles.MANAGEMENT_2,
      BRoles.MOBILE_APP_DEVELOPER,
      BRoles.OTHER_1,
      BRoles.OTHER_2,
      BRoles.PRODUCT_SERVICE_OWNER,
      BRoles.PROJECT_MANAGEMENT_1,
      BRoles.SYSTEM_ANALYST,
      BRoles.TESTER,
      BRoles.UI_UX_DESIGNER,
    ],
  },
  {
    icon: Icons.Code,
    title: 'ИТ-решения и\xa0продукты',
    subtitleList: [
      ['Разработка', 'Аналитика', 'Управление продуктами'],
      ['Дизайн', 'BigData', 'QA'],
    ],
    roles: [
      BRoles.ANALYST,
      BRoles.ARCHITECT,
      BRoles.BACKEND_DEVELOPER,
      BRoles.BI_ANALYST,
      BRoles.BI_DEVELOPER,
      BRoles.BUSINESS_ANALYST,
      BRoles.DATA_ANALYST,
      BRoles.DATA_ENGINEER,
      BRoles.DATA_SCIENTIST,
      BRoles.DEVOPS_ENGINEER,
      BRoles.FRONTEND_DEVELOPER,
      BRoles.IT_INFRASTRUCTURE,
      BRoles.IT_SOLUTION_ADMINISTRATION,
      BRoles.MANAGEMENT_1,
      BRoles.MANAGEMENT_2,
      BRoles.MOBILE_APP_DEVELOPER,
      BRoles.OTHER_1,
      BRoles.OTHER_2,
      BRoles.PRODUCT_SERVICE_OWNER,
      BRoles.PROJECT_MANAGEMENT_1,
      BRoles.SYSTEM_ANALYST,
      BRoles.TESTER,
      BRoles.UI_UX_DESIGNER,
    ],
  },
];

const adaptiveTvCards: Vacancy[] = [
  {
    icon: Icons.Wifi,
    title: 'Продажи тв и\xa0домашнего интернета',
    subtitleList: [['Без опыта', 'Подработка'], ['Агенты', 'Промоутеры'], ['Активные продажи']],
    roles: [BRoles.DIRECT_SALES_AGENT, BRoles.POSTER],
  },
  {
    icon: Icons.Wifi,
    title: 'Продажи тв и\xa0домашнего интернета',
    subtitleList: [
      ['Без опыта', 'Подработка', 'Агенты'],
      ['Промоутеры', 'Активные продажи'],
    ],
    roles: [BRoles.DIRECT_SALES_AGENT, BRoles.POSTER],
  },
];

const adaptiveBusinessCards: Vacancy[] = [
  {
    icon: Icons.NetworkAlt,
    title: 'Развитие бизнеса и\xa0продаж',
    subtitleList: [
      ['Продажи В2В', 'Реклама'],
      ['Маркетинг', 'Продажи В2C'],
      ['Высший менеджмент', 'PR'],
    ],
    roles: [
      BRoles.ADVERTISEMENT_MANAGER,
      BRoles.ANALYTICS_REPORTING_2,
      BRoles.ART_DIRECTOR,
      BRoles.BRANCH_OFFICE_DIRECTOR,
      BRoles.CONTENT_MANAGER_EDITOR,
      BRoles.COPYWRITER,
      BRoles.CUSTOMER_SUPPORT_2,
      BRoles.CUSTOMER_SUPPORT,
      BRoles.DESIGNER,
      BRoles.EVENT_MANAGER,
      BRoles.HR_DIRECTOR,
      BRoles.MANAGEMENT_4,
      BRoles.MANAGEMENT_5,
      BRoles.MARKETER,
      BRoles.MERCHANDISER,
      BRoles.METHODOLOGIST,
      BRoles.OPERATING_DIRECTOR,
      BRoles.OTHER_4,
      BRoles.OTHER_5,
      BRoles.OTHER_6,
      BRoles.OTHER_7,
      BRoles.PARTNERSHIP_SALES_DEVELOPMENT,
      BRoles.PR_MANAGER,
      BRoles.PROJECT_MANAGEMENT_4,
      BRoles.PROJECT_MANAGEMENT_5,
      BRoles.PROJECT_MANAGEMENT_6,
      BRoles.RESEARCH,
      BRoles.RETAIL_SALES_MANAGEMENT,
      BRoles.SALES_ANALYTICS_2,
      BRoles.SALES_ANALYTICS,
      BRoles.SALES_CHANNEL_DEVELOPMENT,
      BRoles.SALES_DIRECTOR,
      BRoles.SALES_REPRESENTATIVE,
      BRoles.SALES_SUPPORT_2,
      BRoles.SALES_SUPPORT,
      BRoles.SALES_TO_CORPORATE_CLIENTS,
      BRoles.SALES_TO_SMB,
      BRoles.SMM_MANAGER,
      BRoles.TECHNICAL_DIRECTOR,
      BRoles.TELESALES,
      BRoles.WORK_WITH_GOVERNMENT_STRUCTURES,
      BRoles.WORK_WITH_TELCOS_2,
    ],
  },
  {
    icon: Icons.NetworkAlt,
    title: 'Развитие бизнеса и\xa0продаж',
    subtitleList: [
      ['Продажи В2В', 'Реклама', 'Маркетинг'],
      ['Продажи В2C', 'Высший менеджмент', 'PR'],
    ],
    roles: [
      BRoles.ADVERTISEMENT_MANAGER,
      BRoles.ANALYTICS_REPORTING_2,
      BRoles.ART_DIRECTOR,
      BRoles.BRANCH_OFFICE_DIRECTOR,
      BRoles.CONTENT_MANAGER_EDITOR,
      BRoles.COPYWRITER,
      BRoles.CUSTOMER_SUPPORT_2,
      BRoles.CUSTOMER_SUPPORT,
      BRoles.DESIGNER,
      BRoles.EVENT_MANAGER,
      BRoles.HR_DIRECTOR,
      BRoles.MANAGEMENT_4,
      BRoles.MANAGEMENT_5,
      BRoles.MARKETER,
      BRoles.MERCHANDISER,
      BRoles.METHODOLOGIST,
      BRoles.OPERATING_DIRECTOR,
      BRoles.OTHER_4,
      BRoles.OTHER_5,
      BRoles.OTHER_6,
      BRoles.OTHER_7,
      BRoles.PARTNERSHIP_SALES_DEVELOPMENT,
      BRoles.PR_MANAGER,
      BRoles.PROJECT_MANAGEMENT_4,
      BRoles.PROJECT_MANAGEMENT_5,
      BRoles.PROJECT_MANAGEMENT_6,
      BRoles.RESEARCH,
      BRoles.RETAIL_SALES_MANAGEMENT,
      BRoles.SALES_ANALYTICS_2,
      BRoles.SALES_ANALYTICS,
      BRoles.SALES_CHANNEL_DEVELOPMENT,
      BRoles.SALES_DIRECTOR,
      BRoles.SALES_REPRESENTATIVE,
      BRoles.SALES_SUPPORT_2,
      BRoles.SALES_SUPPORT,
      BRoles.SALES_TO_CORPORATE_CLIENTS,
      BRoles.SALES_TO_SMB,
      BRoles.SMM_MANAGER,
      BRoles.TECHNICAL_DIRECTOR,
      BRoles.TELESALES,
      BRoles.WORK_WITH_GOVERNMENT_STRUCTURES,
      BRoles.WORK_WITH_TELCOS_2,
    ],
  },
];

const adaptiveOfficeCards: Vacancy[] = [
  {
    icon: Icons.StatUp,
    title: 'Офисы продаж и обслуживания',
    subtitleList: [['Розничные продажи'], ['Офисы продаж'], ['Без опыта работы']],
    roles: [BRoles.STORE_DIRECTOR, BRoles.OFFICE_SPECIALIST],
  },
  {
    icon: Icons.StatUp,
    title: 'Офисы продаж и обслуживания',
    subtitleList: [['Розничные продажи', 'Офисы продаж'], ['Без опыта работы']],
    roles: [BRoles.STORE_DIRECTOR, BRoles.OFFICE_SPECIALIST],
  },
];

const adaptiveCentersCards: Vacancy[] = [
  {
    icon: Icons.HeadsetHelp,
    title: 'Контактные центры',
    subtitleList: [['Поддержка клиентов', 'Телепродажи'], ['Техническая поддержка'], ['Менеджмент'], ['Без опыта']],
    roles: [
      BRoles.B2B_CUSTOMER_SUPPORT,
      BRoles.CUSTOMER_SUPPORT_3,
      BRoles.MANAGEMENT_6,
      BRoles.PROJECT_MANAGEMENT_7,
      BRoles.TECHNICAL_SUPPORT,
      BRoles.TELEPHONE_SALES,
    ],
  },
  {
    icon: Icons.HeadsetHelp,
    title: 'Контактные центры',
    subtitleList: [['Поддержка клиентов', 'Телепродажи'], ['Техническая поддержка', 'Менеджмент'], ['Без опыта']],
    roles: [
      BRoles.B2B_CUSTOMER_SUPPORT,
      BRoles.CUSTOMER_SUPPORT_3,
      BRoles.MANAGEMENT_6,
      BRoles.PROJECT_MANAGEMENT_7,
      BRoles.TECHNICAL_SUPPORT,
      BRoles.TELEPHONE_SALES,
    ],
  },
];

const vacanciesCards: Vacancy[] = [
  {
    icon: Icons.Antenna,
    title: 'Инженерия объектов связи',
    subtitleList: [['Аренда позиций', 'Энергетика'], ['Строительство и эксплуатация сети'], ['Монтаж оборудования']],
    roles: [
      BRoles.ANALYTICS_REPORTING,
      BRoles.ENERGY_ENGINEER,
      BRoles.INSTALLER,
      BRoles.NETWORK_ADMINISTRATOR,
      BRoles.NETWORK_CONSTRUCTION_ENGINEER,
      BRoles.NETWORK_ENGINEER,
      BRoles.NETWORK_OPS_ENGINEER,
      BRoles.NETWORK_PLANNING_OPTIMIZATION_ENGINEER,
      BRoles.OTHER_3,
      BRoles.SERVICE_ENGINEER,
      BRoles.SYSTEM_ENGINEER,
      BRoles.POSITION_RENTAL_SPECIALIST,
      BRoles.PROJECT_MANAGEMENT_3,
      BRoles.WORK_WITH_TELCOS_1,
    ],
  },
];

export const vacanciesCardValues = (isNormal: boolean, isLarge: boolean) => {
  const result = [...vacanciesCards];

  result.splice(0, 0, adaptiveItCards[isNormal && !isLarge ? 1 : 0]);
  result.splice(2, 0, adaptiveTvCards[isNormal && !isLarge ? 1 : 0]);
  result.splice(3, 0, adaptiveBusinessCards[isNormal && !isLarge ? 1 : 0]);
  result.splice(4, 0, adaptiveOfficeCards[isNormal && !isLarge ? 1 : 0]);
  result.splice(5, 0, adaptiveCentersCards[isNormal ? 1 : 0]);

  return result;
};
