import { useEffect, useRef } from 'react';

/** Хук события скролла в конец страницы */
export const useScrollEnd = (callback: () => void) => {
  const callbackRef = useRef<() => void>();
  callbackRef.current = callback;

  useEffect(() => {
    /** Проверка скролла с загрузкой следующей страницы */
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
        return;
      }
      callbackRef.current?.();
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
};
