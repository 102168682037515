import { useInfiniteQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants/endpoints';

import { BVacancyPage } from ':types';
import { API } from ':api';

export interface VacanciesFilter {
  /** Выбранные города */
  cities?: string[];
  /** Для людей с ограниченными возможностями */
  disability?: boolean;
  /** Включение запроса */
  enabled?: boolean;
  /** Выбранный формат работы */
  format?: string[];
  /** Выбранный стаж */
  grades?: string[];
  /** Стажировка */
  internship?: boolean;
  /** Поисковый запрос вакансии */
  name?: string;
  /** Выбраннные направления */
  roles?: string[];
}

const getOffset = (url: string | null) => {
  return url ? new URLSearchParams(url).get('offset') : null;
};

export const useVacancies = ({
  name,
  cities: cityId,
  grades: gradeId,
  enabled,
  format: workFormatId,
  roles: roleId,
  internship: isIntership,
  disability: isLimitedAbility,
}: VacanciesFilter) => {
  return useInfiniteQuery<BVacancyPage>({
    enabled,
    queryKey: ['vacancies', name, cityId, gradeId, workFormatId, roleId, isIntership, isLimitedAbility],
    queryFn: ({ pageParam = 0 }) => {
      return API.get({
        url: Endpoints.vacancies(),
        params: {
          name: name?.length ? name : null,
          city: cityId?.length ? cityId : null,
          grade_id: gradeId?.length ? gradeId : null,
          work_format_id: workFormatId?.length ? workFormatId : null,
          role_id: roleId?.length ? roleId : null,
          is_intership: isIntership || null,
          is_limited_ability: isLimitedAbility || null,
          offset: pageParam || null,
        },
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      const nextOffset = getOffset(lastPage.next);
      return nextOffset || undefined;
    },
  });
};
