import { BWorkGrade } from ':types';

export const GradeMock: BWorkGrade[] = [
  {
    id: '1',
    name: 'Без опыта',
  },
  {
    id: '2',
    name: 'От 1 года',
  },
  {
    id: '3',
    name: 'Более 5 лет',
  },
];
