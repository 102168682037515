import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ChangeEvent, useCallback, useEffect } from 'react';

import { Routes } from ':constants/routes';
import { useWorkFormats } from ':hooks/useWorkFormat';
import { useScrollEnd } from ':hooks/useScrollEnd';

import { isDefined } from ':utils';
import { useCities, useDirection, useGrade, useMobileView } from ':hooks';
import { BCity, BDirection, BWorkFormat, BWorkGrade } from ':types';

/**
 * @description Получение значения параметра поиска из адресной строки
 */
export const useSearchValue = (key: string) => {
  const [searchParams] = useSearchParams();
  return searchParams.get(key);
};

/**
 * @description Получение данных на основании идентификаторов из адресной строки
 */
export const useSelectedData = <T extends Record<string, any>>(key: string, data: T[], prop = 'id') => {
  const selectedIds = useSearchValue(key)?.split(',') ?? [];

  if (!data || !data.length || !selectedIds.length) {
    return [];
  }

  return selectedIds
    .map((selectedId) => {
      return data.find(({ [prop]: id }) => id === selectedId);
    })
    .filter(isDefined);
};

/**
 * @description Запись выбранных идентификаторов в адресную строку
 */
export const useSetSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useCallback(
    (key: string, selectedIds: string[]) => {
      if (!selectedIds.length) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, selectedIds.join(','));
      }
      setSearchParams(searchParams, { preventScrollReset: true });
    },
    [searchParams, setSearchParams],
  );
};

/** Обработчики изменений фильтров */
export const useChangeSearchParams = (addFilter: (newFilters: any) => void) => {
  const setSearchParams = useSetSearchParams();

  const handleSelectChange = (key: string) => (data: BCity[] | BWorkGrade[] | BWorkFormat[]) => {
    const selectedIds = data.map(({ id }) => id);
    setSearchParams(key, selectedIds);
    addFilter({ [key]: selectedIds });
  };

  const handleCheckboxTreeChange = (roleIds: string[]) => {
    setSearchParams('roles', roleIds);
    addFilter({ roles: roleIds });
  };

  const handleCheckboxChange = (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setSearchParams(key, checked ? ['true'] : []);
    addFilter({ [key]: checked });
  };

  const clearFilters = () => {
    setSearchParams('cities', []);
    setSearchParams('grades', []);
    setSearchParams('format', []);
    setSearchParams('disability', []);
    setSearchParams('internship', []);
    setSearchParams('roles', []);
  };

  return {
    handleSelectChange,
    handleCheckboxTreeChange,
    handleCheckboxChange,
    clearFilters,
  };
};

const filterKeys = ['cities', 'grades', 'format', 'disability', 'internship', 'roles'];

/**
 * @description Сохранение и восстановление параметров адресной строки в localStorage
 */
export const usePersistSearchParams = (
  addFilter: (newFilter: any) => void,
  setSearchvalue: (value: string) => void,
) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchName = localStorage.getItem('searchName');
    if (searchName) {
      const searchParams = new URLSearchParams(searchName);
      const searchValue = searchParams.get('name') || '';
      setSearchvalue(searchValue);
      localStorage.removeItem('searchName');
      addFilter({ name: searchValue, enabled: true });
      return;
    }

    const filters = localStorage.getItem('filters');
    if (!filters) {
      addFilter({ enabled: true });
      return;
    }
    // инициализация фильтра из localStorage
    const searchParams = new URLSearchParams(filters);
    const res = filterKeys.reduce<{ [key: string]: string[] | boolean }>((obj, key) => {
      const param = searchParams.get(key)?.split(',');
      if (param) {
        obj[key] = param[0] === 'true' ? true : param;
      }
      return obj;
    }, {});
    addFilter?.({ ...res, enabled: true });

    navigate(
      {
        pathname: Routes.Vacancies,
        search: filters,
      },
      { replace: true },
    );
  }, [navigate, addFilter, setSearchvalue]);

  useEffect(() => {
    localStorage.setItem('filters', search);
  }, [search]);
};

/**
 * @description Получние данных для страницы вакансий
 */
export const useVacanciesData = () => {
  const { data: cities, isLoading: isCitiesLoading } = useCities();
  const { data: directions, isLoading: isDirectionLoading } = useDirection();
  const { data: workFormats, isLoading: isFiltersLoading } = useWorkFormats();
  const { data: grades, isLoading: isGradesLoading } = useGrade();

  const isLoading = isCitiesLoading || isDirectionLoading || isFiltersLoading || isGradesLoading;

  return {
    cities,
    directions,
    isLoading,
    workFormats,
    workGrade: grades,
  };
};

/** Получаем состояние выбранных фильтров */
export const useVacanciesSelect = (
  cities: BCity[],
  workFormats: BWorkFormat[],
  directions: BDirection[],
  workGrade: BWorkGrade[],
) => {
  const selectedCities = useSelectedData('cities', cities);
  const selectedGrades = useSelectedData('grades', workGrade);
  const selectedWorkFormat = useSelectedData('format', workFormats);
  const selectedRoles = useSelectedData(
    'roles',
    directions.flatMap(({ roles }) => roles),
    'role_id',
  );
  const isDisability = Boolean(useSearchValue('disability'));
  const isInternship = Boolean(useSearchValue('internship'));

  return {
    isDisability,
    isInternship,
    selectedCities,
    selectedGrades,
    selectedRoles,
    selectedWorkFormat,
  };
};

/** Работа с localStorage */
export const useLocalStorage = () => {
  const setSearchParams = useSetSearchParams();

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('vacancyFilters') as any);
    if (savedFilters) {
      setSearchParams(
        'cities',
        ((savedFilters.cities as BCity[]) || []).map(({ id }) => id),
      );

      setSearchParams(
        'grades',
        ((savedFilters.grades as BWorkGrade[]) || []).map(({ id }) => id),
      );

      setSearchParams(
        'format',
        ((savedFilters.workFormat as BWorkFormat[]) || []).map(({ id }) => id),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Подгрузка фильтров из localStorage в строку поиска (нужна только при инициализации)
};

/** Хук для обработки размера экрана и скролла */
export const usePageObserve = (
  isFetchingNextPage: boolean,
  fetchNextPage: () => void,
  setModalFilterOpen: (value: boolean) => void,
) => {
  const { isLargeViewUp } = useMobileView();

  useScrollEnd(() => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  });

  /** Закрываем модалку с фильтрами при переходе на large view */
  useEffect(() => {
    if (isLargeViewUp) {
      setModalFilterOpen(false);
    }
  }, [isLargeViewUp, setModalFilterOpen]);

  return { isLargeViewUp };
};
