import { Icons } from '@beeline/design-tokens/js/iconfont/icons';

import { BVacancyPage, WorkIsCard } from ':types';

export const workWithUsBenefits: WorkIsCard[] = [
  {
    title: 'Связь с миром',
    subtitleList: [
      'Корпоративная связь',
      'Cкидки на домашний интернет и ТВ',
      '250 Гб в облако билайн',
      'Бесплатная связь для близких',
    ],
  },
  {
    icon: Icons.Star,
    title: 'Радоваться жизни',
    subtitleList: [
      'Совместные праздники, новогодние подарки для детей ',
      'Скидки от партнеров - SkyEng, СберМаркет, Самокат, Lamoda и другие',
      'Награда "Бриллиантовая пчела" лучшим сотрудниках',
    ],
  },
  {
    icon: Icons.Group,
    title: 'Быть среди своих',
    subtitleList: [
      'Сообщества по интересам: кино, шахматы, книги, фитнес, киберспорт',
      'Магазин корпоративного мерча',
      'Спортивные мероприятия',
    ],
  },
  {
    icon: Icons.Heart,
    title: 'Заботиться о здоровье',
    subtitleList: [
      'ДМС со стоматологией',
      'Скидки на ДМС для близких',
      'Дополнительно 2 дня отпуска',
      'Доплата по больничному листу',
    ],
  },
  {
    icon: Icons.Book,
    title: 'Учиться и расти',
    subtitleList: ['Курсы и программы от билайн университета', 'Коучинг', 'Программы карьерного развития'],
  },
  {
    icon: Icons.HeadsetHelp,
    title: 'Получать поддержку',
    subtitleList: ['Страховки от несчастных случаев и в поездках', 'Материальная помощь', 'Корпоративный психолог'],
  },
];

export const VacanciesPageMock: BVacancyPage = {
  count: 7,
  next: 'http://127.0.0.1:8000/api/v1/vacancies/?limit=2&offset=2',
  previous: null,
  results: [
    {
      id: '21',
      name: 'devops инженер (mass)',
      city: ['Самара', 'Москва'],
      grade: 'Более 5 лет',
      gross_salary_from: '260.000 руб.',
      role: 'Devops',
      job_type_id: '4',
      work_format: 'Дистанционный формат',
    },
    {
      id: '22',
      name: 'devops инженер2 (prof)',
      city: ['Москва'],
      grade: 'От 1 года',
      gross_salary_from: '260.000 руб.',
      role: 'Devops',
      job_type_id: '1',
      work_format: 'Гибридный формат',
    },
    {
      id: '23',
      name: 'devops инженер2 (intern)',
      city: ['Москва'],
      grade: 'Более 5 лет',
      gross_salary_from: '260.000 руб.',
      role: 'Devops',
      job_type_id: '2',
      work_format: 'Гибридный формат',
    },
  ],
};
