import { FC } from 'react';
import { Tree, TreeNode, Typography } from '@beeline/design-system-react';
import cn from 'clsx';

import styles from './styles.module.scss';
import { filteredDirections, TreeNodeProp } from './utils';

import { BDirection } from ':types';

interface Props {
  /** Массив направлений */
  directions: BDirection[];
  /** Коллбек изменения */
  onChange?: (selectedIds: string[]) => void;
  /** Фильтр поиска */
  searchValue: string;
  /** Массив идентификаторов выбранных ролей */
  values?: string[];
}

const countRender = (count: number) => <Typography variant="caption">{count}</Typography>;

/**
 * @description Компонент дерева чекбоксов
 */
export const CheckboxTree: FC<Props> = ({ directions, onChange: handleChange, searchValue, values }) => {
  // TODO можно попробовать объединить фильтрацию с рендером
  const renderNodes = (nodes?: TreeNodeProp[]) =>
    nodes?.map(({ id, name, count, childs, checked, hidden }) => (
      <TreeNode
        key={id}
        checked={checked}
        className={cn(hidden && styles.hideElement)}
        custom={countRender(count)}
        disabled={!count}
        id={id}
        title={name}
      >
        {renderNodes(childs)}
      </TreeNode>
    ));

  return (
    <Tree expandOnArrow={false} multiselect onChange={handleChange} size="small" style={{ margin: '0 -16px' }}>
      {renderNodes(filteredDirections(directions, searchValue, values))}
    </Tree>
  );
};
